import { withFormsy } from 'formsy-react';
import React from 'react';
import "./formsy.css";

class FormsyInput extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.regExpVar = "";
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    // An error message is passed only if the component is invalid
    // CUSTOMIZED TO WORK WITH DYNAMIC REGEXP
    const errorMessage = this.regExpVar !== "" ? this.regExpVar :  this.props.errorMessage;

    return (
      <div className={this.props.classes ? this.props.classes : ""}>
        {/* { this.props.label && (
          <div className="text-white">{this.props.label}</div>
        )} */}
        <label className="text-white" style={{fontSize: "20px",color: "#e2e8f0"}} for={"formsy-" + this.props.name}>{this.props.placeholder}</label>

        <textarea id={this.props.id ? this.props.id : null } className="custom-textarea" id={"formsy-" + this.props.name} rows="4" cols="50" onChange={this.changeValue} type={this.props.type ? this.props.type : "text" } value={this.props.value || ''} />
        <span className="text-red-700 text-sm">{errorMessage}</span>
      </div>
    );
  }
}

export default withFormsy(FormsyInput);